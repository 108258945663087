@import '@fontsource-variable/oswald';
@import '@fontsource/source-sans-pro';
@import '@fontsource/anton';

@tailwind base;
@tailwind components;
@tailwind utilities;

html, body, #root {
    height: auto;
}

body {
    font-family: 'Source Sans Pro', sans-serif;
    letter-spacing: 0.01em;
}

h1, h2, h3 {
    font-family: 'Oswald', sans-serif;
    letter-spacing: -0.02em;
}

